*, :before, :after {
  box-sizing: border-box;
}

body, p, h1, h2, h3, h4, h5, h6, input {
  margin: 0;
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.eot"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-regular.svg#robotoregular") format("svg");
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.woff") format("woff"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.ttf") format("truetype"), url("//img2.cncenter.cz/fonts/roboto/roboto-v15-latin-ext_latin-700.svg#robotobold") format("svg");
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/Montserrat/eot/Montserrat-Black.eot"), url("//img2.cncenter.cz/fonts/Montserrat/eot/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/Montserrat/woff/Montserrat-Black.woff") format("woff"), url("//img2.cncenter.cz/fonts/Montserrat/woff2/Montserrat-Black.woff2") format("woff2");
}

.main-container {
  margin-top: 0;
  padding: 0;
}

.page-header {
  padding: 0 10px;
}

.page-main {
  padding-left: 10px;
  padding-right: 10px;
}

.page-main--moved-down {
  padding-top: 25px;
}

.fullwidth-image {
  height: auto;
}

.main-title {
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 30px;
}

.page-navigation {
  z-index: 1;
  border: none;
}

.page-navigation__list {
  z-index: 2;
  text-align: center;
  background-color: #262859;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.page-navigation__list[data-state="open"] {
  display: flex;
}

.page-navigation__item {
  margin: 0 0 20px;
}

.page-navigation__item:last-child {
  margin: 0;
  position: static;
  transform: none;
}

.page-navigation__link {
  padding: 10px 20px;
}

.page-navigation__hamburger {
  z-index: 3;
  background: #ca2226 no-repeat;
  border: none;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
}

.page-navigation__hamburger:before {
  content: "";
  background-color: #fff;
  width: 18px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 -5px #fff, 0 5px #fff;
}

.page-navigation__list[data-state="open"] ~ .page-navigation__hamburger {
  background-image: url("//img2.cncenter.cz/images/blesk-jarmark/close.svg");
}

.page-navigation__list[data-state="open"] ~ .page-navigation__hamburger:before {
  content: none;
}

.header-image {
  margin: 0 -10px -45px;
}

.header-image__logo {
  justify-content: center;
  align-items: center;
  width: 201px;
  margin-bottom: 0;
  display: flex;
  top: 34px;
  left: 50%;
  transform: translateX(-50%);
}

.main-leading {
  margin-bottom: 65px;
}

.main-leading__title {
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 30px;
}

.main-prize {
  flex-direction: column;
  position: relative;
}

.main-prize__image-wrapper {
  margin-bottom: 10px;
}

.main-prize__body {
  width: auto;
  margin: 0;
}

.main-prize__sticker {
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
}

.main-prize__sticker-compete {
  background-image: url("//img2.cncenter.cz/images/blesk-jarmark/sticker-compete.png");
  background-repeat: no-repeat;
  width: 221.7px;
  height: 130px;
  display: none;
  position: absolute;
  top: -65px;
}

.main-prize__sticker-compete--visible {
  display: block;
}

.main-prize__text {
  margin-bottom: 15px;
}

.prizes__list {
  margin-top: -15px;
  margin-left: -5px;
  padding-top: 15px;
}

.prizes__item {
  margin-top: 15px;
  margin-left: 5px;
}

.prize-window {
  width: 165px;
  margin-top: 0;
  margin-right: 0;
  padding-top: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.prize-window:before, .prize-window:after {
  width: 24px;
  height: 175px;
  top: -7px;
}

.prize-window:before {
  left: 2px;
}

.prize-window:after {
  right: 2px;
}

.prize-window__sticker {
  width: 51px;
  height: 51px;
  font-size: 10px;
  line-height: 12px;
  top: -27px;
  right: -5px;
}

.prize-window__image {
  width: 117px;
  height: 117px;
}

.overlay__inner {
  padding: 65px 0;
}

.popup {
  padding-top: 20px;
  padding-bottom: 50px;
}

@media (max-width: 800px) {
  .popup {
    width: 600px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 600px) {
  .popup {
    width: 360px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 360px) {
  .popup {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.article__title {
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 30px;
}

.article__perex {
  margin-bottom: 40px;
  line-height: 25px;
}

.popup__close-button {
  width: 39px;
  height: 39px;
  top: 10px;
  right: 10px;
}

.article__logo {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .article__bottom-container {
    width: 300px;
    margin-top: 30px;
  }

  .article__bottom-container--winners {
    margin-top: 60px;
  }

  .article__bottom-container--poll {
    margin-top: 0;
  }
}

@media (max-width: 360px) {
  .article__bottom-container {
    width: 280px;
  }
}

.gallery__button {
  width: 40px;
}

.today-prize-info {
  padding-bottom: 30px;
}

.today-prize-info__subtitle {
  margin-bottom: 15px;
}

.form-element--checkbox {
  margin-top: 10px;
}

.competition-form .form-element {
  width: 100%;
}

.competition-form .form-element--quarter-width {
  width: 157px;
}

.competition-form {
  max-width: 100%;
}

.competition-form__title {
  font-size: 20px;
  line-height: 26px;
}

.competition-form__subtitle {
  font-size: 16px;
  line-height: 20px;
}

.competition-form__date {
  margin-bottom: 30px;
}

.competition-form__button, .competition-form__fill-invitation {
  width: 100%;
}

.competition-form .form-element {
  margin-right: 0;
}

.article-page__content h3 {
  font-size: 20px;
  line-height: 25px;
}

.article-page__content ul {
  margin-top: 20px;
}

.article-page__content li {
  padding-left: 40px;
}

.article-page__content li:before {
  top: 8px;
  left: 20px;
}

.article-page table {
  width: 250px;
}

.recentarticles {
  width: 340px;
  padding-bottom: 30px;
}

@media (max-width: 340px) {
  .recentarticles {
    width: auto;
  }
}

.recentarticle {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.recentarticle__image-link {
  width: 153px;
  height: 81px;
  margin-right: 10px;
}

.recentarticle__text {
  font-size: 17px;
  line-height: 19px;
}

.winners-page {
  width: 250px;
}

